import React from 'react';
import Layout from '../components/Shared/Layout/Layout';
import { SEO } from '../components/Shared/SEO/SEO';
import { TrainingPlaces } from '../components/Shared/TrainingPlaces/TrainingPlaces';
import { ContactInformationSection } from '../components/Contact/ContactInformationSection/ContactInformationSection';
import { ContactFromSection } from '../components/Contact/ContactFormSection/ContactFormSection';
import { PartnersSection } from '../components/Shared/PartnersSection/PartnersSection';

export const KontaktPage = () => {
  return (
    <Layout>
      <SEO title={'Kontakt'} />
      <ContactInformationSection />
      <TrainingPlaces borderRight />
      <ContactFromSection />
      <PartnersSection />
    </Layout>
  );
};

export default KontaktPage;
