import React from 'react';
import { styled } from 'linaria/react';
import { theme } from "../../Shared/Theme/Theme";
import { Section } from '../../Shared/Section/Section';
import { HeadingTwo } from '../../Shared/Typography/HeadingTwo/HeadingTwo';
import { ContactUsForm } from '../../Landing/ContactUsSection/components/ContactUsForm/ContactUsForm';
import Container from '@material-ui/core/Container';


export interface ContactFromSectionProps {
  className?: string;
  borderRight?: boolean;
}


const ContactFromSectionInner = (props: ContactFromSectionProps) => {
  return (
    <Section>
      <Container className={props.className} maxWidth="md">
        <HeadingTwo className="center heading">Napíš nám správu</HeadingTwo>
        <ContactUsForm />
      </Container>
    </Section>
  );
};

export const ContactFromSection = styled(ContactFromSectionInner)`
  .heading {
    margin-bottom: 14px;
  }
  @media (min-width:${theme.breakpoints.sm}px){
    padding: 0 10vw;
  }
`;