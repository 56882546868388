import { Grid } from '@material-ui/core';
import { styled } from 'linaria/react';
import { muiTheme, theme } from '../../Shared/Theme/Theme';
import React from 'react';
import { Borders } from '../../Shared/Borders/Borders';
import { ContainerCustom } from '../../Shared/ConatinerCustom/ContainerCustom';
import { Section } from '../../Shared/Section/Section';
import { HeadingThree } from '../../Shared/Typography/HeadingThree/HeadingThree';
import { HeadingTwo } from '../../Shared/Typography/HeadingTwo/HeadingTwo';
import { BorderedCard } from '../../Shared/BorderedCard/BorderedCard';
import { contactUsLinks } from '../../Landing/ContactUsSection/hooks/useContactUsLinks';

export interface ContactInformationSectionProps {
  className?: string;
  borderRight?: boolean;
}

const ContactInformationSectionInner = (
  props: ContactInformationSectionProps
) => {
  return (
    <Section className={props.className}>
      <ContainerCustom>
        <HeadingTwo className="center heading">
          Kontakt a tréningové miesta
        </HeadingTwo>

        <Borders bottomLeft>
          <div className="contact">
            <HeadingThree className="dark">Kontaktné informácie</HeadingThree>
            <Grid container spacing={4} className="contact-cards">
              {contactUsLinks.slice(0, 2).map(link => (
                <Grid key={link.text} item xs={12} sm={6}>
                  <BorderedCard heading={link.text} className="wide">
                    <img src={link.icon}></img>
                  </BorderedCard>
                </Grid>
              ))}
            </Grid>
          </div>
        </Borders>
      </ContainerCustom>
    </Section>
  );
};

export const ContactInformationSection = styled(ContactInformationSectionInner)`
  .contact-cards {
    margin-top: 0.6rem;
  }

  .heading {
    margin-bottom: 1rem;
  }

  .contact {
    padding: 0 40px 40px 40px;
  }

  img {
    /* make icons fit */
    max-height: 60px;
    max-width: 60px;
    object-fit: none;

    margin: 25px 0 -15px 0;
    transition: all ${theme.hover.transitionTime}ms ease-in-out;
  }

  .heading {
    font-size: 2.4rem !important;
    margin: 0;
  }

  .wide {
    width: 350px !important;
    max-width: 350px !important;
  }

  ${muiTheme.breakpoints.down('sm')} {
    .wide {
      width: 300px !important;
      max-width: 3000px !important;
    }
    .heading {
      font-size: 2rem !important;
      margin: 0;
    }
    .contact {
      padding: 0;
    }
  }
`;
